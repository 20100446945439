<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <h1
          style="
            font-size: 30px;
            font-weight: 400;
            font-family: LexendRegular;
            margin-bottom: 30px;
          "
        >
          Book Shikaras
        </h1>
        <Main />
      </v-flex>
      <v-flex xs12>
        <router-view />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Main from "./Search.vue";

export default {
  components: {
    Main,
  },
  data() {
    return {};
  },
};
</script>
