<template>
  <v-app>
    <header2 v-if="$route.meta.isagent == true" />
    <AppHeader v-if="$route.meta.isagent == false" />
    <router-view :key="$route.fullPath" />
    <AppFooter v-if="$route.meta.isagent == false" />
    <footer2 v-if="$route.meta.isagent == true" />

    <!-- Install PWA prompt -->
    <div v-if="showInstallPrompt" class="install-prompt">
      <p>Install our app for a better experience!</p>
      <button @click="installApp">Install</button>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import store from "./store";
import AppHeader from "./components/Common/appHeader";
import header2 from "./components/Common/AppHeader2";
import AppFooter from "./components/Common/appFooter";
import footer2 from "./components/Common/appFooter2";
import "./assets/font.css";
import "./assets/style.css";

export default {
  name: "App",
  components: {
    AppHeader,
    header2,
    footer2,
    AppFooter,
  },
  data: () => ({
    verificationStatus: "",
    sideNav: false,
    level: "",
    showInstallPrompt: false,
    deferredPrompt: null,
  }),
  beforeMount() {
    if (typeof localStorage.getItem("token") == "string") {
      this.getProfile();
    }
  },
  created() {
    // Add PWA install prompt listener
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.showInstallPrompt = true;
    });

    // Handle app installation
    window.addEventListener("appinstalled", () => {
      this.showInstallPrompt = false;
      this.deferredPrompt = null;
      console.log("PWA was installed");
    });
  },
  methods: {
    getProfile() {
      axios({
        method: "GET",
        url: "/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
          .then((response) => {
            if (response.data.status == true) {
              this.level = response.data.data.level;
              store.commit("loginUser", response.data.status);

              const nameValue = response.data.data.name;
              localStorage.setItem("profiledata", JSON.stringify(response.data));

              localStorage.setItem("name", nameValue);
              this.verificationStatus = response.data.data.verificationStatus;

              store.commit("userData", response.data.data);
              store.commit("level", response.data.data.level);
              store.commit("userType", response.data.data.type);
              if (this.verificationStatus == "Pending" && this.level == 0) {
                this.$router.push("/StepOne");
              } else if (this.verificationStatus == "Pending" && this.level == 1) {
                this.$router.push("/StepTwo");
              } else if (this.verificationStatus == "Pending" && this.level == 2) {
                this.$router.push("/StepThree");
              } else if (this.verificationStatus == "Pending" && this.level == 3) {
                this.$router.push("/StepFour");
              } else if (this.verificationStatus == "Pending" && this.level == 4) {
                this.$router.push("/finalPage");
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    installApp() {
      this.showInstallPrompt = false;
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
          this.deferredPrompt = null;
        });
      }
    },
    // getLink() {
    //   axios({
    //     method: "GET",
    //     url: "/generate/referal/link",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status) {
    //         this.$store.commit("link", response.data.link);
    //         this.$store.commit("code", response.data.code);
    //       } else {
    //         this.showSnackBar = true;
    //         this.msg = response.data.msg;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // getGuestId() {
    //   axios({
    //     method: "GET",
    //     url: "/user/newguest",
    //   })
    //     .then((response) => {
    //       if (response.data.status) {
    //         localStorage.setItem("guestId", response.data.id);
    //         this.$store.commit("guestIdUpdation", response.data.id);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // winStepper(windowData) {
    //   this.sideNav = windowData.sideNav;
    // },
  },
};
</script>

<style>
.install-prompt {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.install-prompt button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.install-prompt button:hover {
  background-color: #0056b3;
}
</style>

